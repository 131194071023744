// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


/*
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/css/all"
import "@fortawesome/fontawesome-free/js/all";
import ahoy from "ahoy.js";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
*/

import "@fortawesome/fontawesome-free/css/all"
import ahoy from "ahoy.js"
import "inputmask"

import "@hotwired/turbo-rails"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@rails/actiontext")
require("jquery")
require("channels")
require("jquery-ui-dist/jquery-ui")



require("trix")
require("@rails/actiontext")

require("chartkick/chart.js")


import "simplebar"
import 'simplebar/dist/simplebar.css'



global.Rails = Rails;







//*****************************************




$(document).on('turbolinks:load', function(){
  
})



$(document).on('click', '.modal-close', function(){
  $('.modal-main').hide()  
})

$(document).on('click', '.sub-modal-close', function(){
  $('.modal .modal-title').text('')
  $('.modal .modal-container').html('')
  $('.modal').hide()  
})


$(document).on('keydown', function(k){
  if( k.which == 27 && $('.modal').is(':visible')) {
    $('.modal').hide()
  } else {

    if( k.which == 27 && $('.modal-main').is(':visible')) {
      $('.modal-main').hide()
      $('.modal-main .modal-window').removeClass('centralize')
    }
  }
})
/*
$(document).on('touchstart', function(e){
  $('body').css('background', 'red')
  console.log(e)
})
$(document).on('touchend', function(e) {
  $('body').css('background', 'blue')
  console.log(e)
})
*/

/
/*
$(document).on('touchmove', function(e) {
  $('body').css('background', 'green')
  console.log(e)
})


$(document).on('swipe', function(e) {
  $('body').css('background', 'green')
  console.log(e)
})
*/

/**********************
 * DIRECT UPLOADS
 * ****************/
// direct_uploads.js

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})




//**********************************//


/*
$(document).on('trix-attachment-add', 'trix-editor', function(e){
  console.log('attachemt add', e)
})
*/

addEventListener("trix-attachment-add", function(event) {
  /*
  console.log(event)
  debug = $(event.target)
  // /*
  attachment_url = $(event.target).data('att-url')
  
  console.log(attachment_url)
  
  var msg = validaAttachment(event.attachment.file)
  if ( msg.length == 0 ) {
    uploadFileAttachment(event.attachment)
  } else {
    $(event.target).trigger('uploadErrorMessage', msg)
    return msg
  }
  // */
})

window.addEventListener("trix-file-accept", function(event) {  
  const acceptedTypes = ['image/jpeg', 'image/png', 'application/zip', 'application/pdf']  
  const maxSize = 10000000 //10MB
  var msg = ''
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()  
    msg += 'Formato de arquivo inválido. Permitidos: JPG, PNG, PDF, ZIP.'
  }
  if (event.file.size >= maxSize) {
    event.preventDefault()
    msg += ' Excede o tamanho máximo permitido ( 10MB.)'
  }
  if ( msg.length > 0 ) {
    alert(msg)
  }
})

/*
window.addEventListener("trix-attachment-remove", function(event){
  console.log('remove')
  debug = event
  console.log(event.type, event.attachment.getAttributes())
})
*/
/*
function validaAttachment(file) {
  const maxSize = 10000000 //10MB
  const format = ['jpeg', 'jpg', 'png', 'zip', 'pdf']
  
  var msg = ''
  
  if ( file.size >= maxSize ) {
    msg += 'Excede o tamanho máximo permitido - 10 MB.' 
  }
  if ( format.indexOf(file.type.split('/')[1]) === -1 ) {
    msg += 'Formato de arquivo inválido. Formatos Permitidos: Imagem ( JPEG, JPG, PNG). Arquivos (PDF, ZIP)'
  }
    
  return msg
  
}

function uploadFileAttachment(attachment) {
  uploadFile(attachment.file, setProgress, setAttributes)

  function setProgress(progress) {
    attachment.setUploadProgress(progress)
  }

  function setAttributes(attributes) {
    attachment.setAttributes(attributes)
  }
}


function uploadFile(file, progressCallback, successCallback) {
  var key = createStorageKey(file)
  var formData = createFormData(key, file)
  var xhr = new XMLHttpRequest()
  var attachment_url = '/rails/active_storage/direct_uploads'
  xhr.open("POST", attachment_url, true)

  xhr.upload.addEventListener("progress", function(event) {
    var progress = event.loaded / event.total * 100
    progressCallback(progress)
  })

  xhr.addEventListener("load", function(event) {
    if (xhr.status == 204) {
      var attributes = {
        url: attachment_url + key,
        href: attachment_url + key + "?content-disposition=attachment"
      }
      successCallback(attributes)
    }
  })

  xhr.send(formData)
}

function createStorageKey(file) {
  var date = new Date()
  var day = date.toISOString().slice(0,10)
  var name = date.getTime() + "-" + file.name
  return [ "tmp", day, name ].join("/")
}

function createFormData(key, file) {
  var data = new FormData()
  data.append("key", key)
  data.append("Content-Type", file.type)
  data.append("file", file)
  return data
}
*/
